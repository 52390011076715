<template>
  <page-main
    title="Ok! Let's take a look at your Cover Letter!"
    :aside="[
      'Review your cover letter, modify as needed, then download in the format you need.',
    ]"
    noBottom
    :error="error"
  >
    <v-container style="position: relative">
      <v-overlay :absolute="true" v-if="overlay">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-row class="pb-3" justify="center">
        <v-col cols="12" sm="5" md="4" lg="4">
          <div>
            <p class="text-h6 mb-2">Cover Letter</p>
            <p class="text-body-2 text--secondary font-italic mb-0">
              A professional cover letter
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="5" md="5" lg="4">
          <v-btn
            block
            outlined
            @click="downloadPdf()"
            id="download-pdf"
            class="mt-3"
            >Download</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <div v-if="errorOnLoad">
            <div
              class="d-flex flex-column justify-center align-center error-box"
            >
              <div class="pb-5 error-box-content">
                <h1>Ouch!</h1>
                <h2>We couldn't load your resume!</h2>
              </div>
              <div class="error-box-content">
                <div class="font-italic pb-2">
                  We're looking into the problem - in the mean time you can try
                  reloading the page:
                </div>
                <v-btn @click="refresh()" outlined>Refresh</v-btn>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="resume-backing pa-3 mb-4">
              <img
                v-if="resumeImageSrc.length > 0"
                :src="resumeImageSrc"
                class="resume-img"
                alt="Resume View"
              />
              <div v-else class="resume-placeholder"></div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as log from "../../utility/logging/logger";
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import DataAccess from "../../report/DataAccess.js";
import * as ReportAPI from "../../api/ReportApi.js";
import CoverLetterBuilder from "../../reportCover/CoverLetterBuilder";

// @ is an alias to /src
export default {
  name: "CoverLetterReview",
  components: {},
  data: () => ({
    errorMsg: false,
    resName: "FirstName, LastName",
    fileName: "Resume",
    address: "Address | Email | Phone",
    professonalSummary: "Professional Summary Text",
    skills: "Skills and Abilities Text",
    education: [],
    workExp: [],
    hardSkillsDisp: "",
    volunteerDisp: "",
    professionalOrgDisp: "",
    professionalCertDisp: "",
    interestsDisp: "",
    renderReport: {},
    reportRequest: {},
    builder: {},
    token: "",
    dateExpires: {},
    overlay: true,
    errorOnLoad: false,
    error: "",
    imgData: "",
  }),
  async mounted() {
    this.dateExpires = new Date(new Date().getTime() + -60 * 1000); // set to one minute ago to init
    await this.initReportBuilder();
  },
  computed: {
    ...mapFields("name", { nameDisplay: "displayValue" }),
    ...mapFields("address", { addressDisplay: "displayValue" }),
    ...mapFields("hardSkill", ["hardSkills", "hardSkillLabels"]),
    ...mapFields("softSkill", ["softSkills"]),
    ...mapFields("licenseCert", ["licensesCertsDisplay"]),
    ...mapFields("adjective", ["selectedAdjectives", "adjectiveDisplay"]),
    ...mapFields("role", ["selectedRoles", "roleDisplay"]),
    ...mapFields("language", ["languagesDisplay"]),
    ...mapFields("organization", [
      "volunteerOrgDisplay",
      "professionalOrgDisplay",
    ]),
    ...mapFields("personalInterest", ["personalInterestsDisplay"]),
    ...mapFields("careerInterest", ["careerInterestsDisplay"]),
    ...mapFields("work", ["workDict"]),
    ...mapFields("education", ["educationDict"]),
    ...mapFields("settings", ["reportURL"]),
    ...mapFields("finalInfo", ["otherSkills", "points"]),

    ...mapFields("targetCompany", [
      "targetRole",
      "targetCompany",
      "companyReasons",
      "companyGoals",
    ]),
    ...mapFields("targetIndustry", ["targetIndustry", "industryReasons"]),
    ...mapGetters("name", ["fullName", "shortName"]),
    ...mapGetters("strengths", { strList: "fullList" }),
    ...mapGetters("education", { eduList: "fullList" }),
    ...mapGetters("work", { workList: "fullList" }),
    resumeImageSrc() {
      if (this.imgData.length === 0) {
        return "";
      } else {
        return this.imgData;
      }
    },
  },
  watch: {},
  methods: {
    async initReportBuilder() {
      try {
        this.overlay = true;
        this.errorOnLoad = false;

        // Get Token
        let da = new DataAccess(this.reportURL);
        let token = await this.getToken();
        if (token === null) {
          log.logError(
            "Token was not retrieved successfully!",
            "CoverLetterReview.initReportBuilder"
          );
          this.errorOnLoad = true;
          this.overlay = false;
          return;
        }

        // Setup reportBuilder, build reportRequest object
        this.buildReportRequest();

        // Retrieve image from report server
        let image = await da.GetReportPNG(this.reportRequest, token);
        this.imgData = URL.createObjectURL(image);

        this.overlay = false;
      } catch (error) {
        log.logError(
          "Unhandled error in initReportBuilder code",
          "CoverLetterReview.initReportBuilder"
        );
        log.logError(`${error.name}: ${error.message}`);
        this.errorOnLoad = true;
        this.overlay = false;
      }
    },
    refresh() {
      log.logWarning(
        "User triggered refresh of Resume page due to error.",
        "CoverLetterReview.refresh"
      );
      window.location.href = "/Review";
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    buildReportRequest() {
      this.builder = new CoverLetterBuilder();

      this.reportRequest = this.builder.BuildReport(
        this.shortName,
        this.fullName,
        this.targetRole,
        this.targetCompany,
        this.hardSkills,
        this.targetIndustry?.text ?? "",
        this.industryReasons,
        this.companyReasons,
        this.companyGoals,
        this.adjectiveDisplay,
        this.roleDisplay,
        this.strList,
        this.careerInterestsDisplay
      );
    },
    async getToken() {
      if (new Date() > this.dateExpires) {
        var tokenResult = await ReportAPI.GetReportToken();
        if (tokenResult.success) {
          this.token = tokenResult.data.access_token;
          this.dateExpires = new Date(
            new Date().getTime() + tokenResult.data.expires_in * 1000
          );
          return this.token;
        } else {
          return null;
        }
      } else {
        return this.token;
      }
    },
    async downloadPdf() {
      try {
        let da = new DataAccess(this.reportURL);

        var token = await this.getToken();
        if (token === null) {
          log.logError(
            "Token was not retrieved successfully!",
            "CoverLetterReview.downloadPdf"
          );
          this.error =
            "Ouch! We had an unexpected error while downloading your Resume.";
          return;
        }

        let report = await da.GetReportPDF(this.reportRequest, token);
        this.downloadFile(report, `${this.nameDisplay} Resume.pdf`);
      } catch (error) {
        log.logError(
          "Unhandled error while downloading pdf",
          "CoverLetterReview.downloadPdf"
        );
        this.error =
          "Ouch! We had an unexpected error while downloading your Resume.";
      }
    },
    downloadFile(blob, fileName) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },
  },
};
</script>

<style scoped>
.error-box {
  width: 636px;
  height: 816px;
  background-color: var(--v-resumeErrorBox-base);
  color: var(--v-resumeErrorText-base);
}

.error-box-content {
  width: 70%;
}

.resume-placeholder {
  width: 612px;
  height: 792px;
  background-color: var(--v-resumePlaceholder-base);
}

.resume-backing {
  background-color: var(--v-resumeBacker-base);
  max-height: 816px;
  max-width: 636px;
}

.resume-img {
  max-width: 100%;
  height: auto;
}
</style> 