import ReportRequest from "../report/ReportRequest";
import * as disp from "../utility/display";

export default class CoverLetterBuilder {

    ReportName = "coverletter";
    #request;

    BuildReport(shortName, fullName, targetPosition, targetCompany, hardSkillsArr, targetIndustry, industryAttractive, roleAttractive, companyGoals, adjectives, roles, strengthArray, careerInterest) {
        this.#request = new ReportRequest();

        // date
        let dateOpts = { year: 'numeric', month: 'long', day: 'numeric' };
        let dateStr = (new Date()).toLocaleDateString("en-us", dateOpts);

        this.#request.AddLine("date", dateStr);

        // first paragraph
        let hardSkills = disp.BuildCommaString("", hardSkillsArr.filter(h => h.level === 1 || h.level === undefined));
        let line1 = `My name is ${shortName}, and I want to be considered for the ${targetPosition} position at ${targetCompany}. I believe my experience in ${hardSkills} can bring value to your organization.`;
        let line2 = `I am interested in the ${targetIndustry.trim()} industry: ${industryAttractive.trim()}${disp.PunctuationIfNeeded(industryAttractive.trim(), '.')}`;
        
        if (!disp.IsNullorWhitespace(roleAttractive)) {
            line2 += ` ${disp.RemoveEndPunctuation(roleAttractive)}`;
        }

        if (!disp.IsNullorWhitespace(companyGoals)) {
            if (!disp.IsNullorWhitespace(roleAttractive)) {
                line2 += ', and';
            }
            line2 += ` ${companyGoals}${disp.PunctuationIfNeeded(companyGoals.trim(), '.')}`;
        } else {
            line2 += '.';
        }

        line2 += ' I believe your firm can provide a challenging, yet exciting opportunity to achieve, grow, and learn.';

        let line3 = `I am ${disp.GetLeadingArticle(adjectives)} ${adjectives} professional with roles as ${disp.GetLeadingArticle(roles)} ${roles}.`;

        if (strengthArray.length > 0) {
            line3 += ` The following are key attributes and skills that I can bring to your company:`;
        }

        this.#request
            .StartRepeat("para1")
            .StartRepeatItem()
            .AddLine("para1Line", line1)
            .EndRepeatItem()
            .StartRepeatItem()
            .AddLine("para1Line", line2)
            .EndRepeatItem()
            .StartRepeatItem()
            .AddLine("para1Line", line3)
            .EndRepeatItem()
            .EndRepeat("para1");

        // strengths
        this.#request.StartRepeat("strengths");
        strengthArray.forEach(line => {
            let strLine = `<bold>${line.display}:</bold> ${line.showCase}${disp.PunctuationIfNeeded(line.showCase.trim(), '.')} ${line.howApply}${disp.PunctuationIfNeeded(line.howApply.trim(), '.')}`;
            this.#request.StartRepeatItem()
                .AddLine("strLine", strLine)
                .EndRepeatItem();
        });
        this.#request.EndRepeat("strengths");

        // final lines
        let line4 = `I believe that through my exposure to ${hardSkills} and my interest in ${careerInterest}, I could be an effective asset to your organization. I am looking forward to an opportunity to learn from the department, grow my skills, and contribute to the collective initiatives of the overall organization.`
        let line5 = `Thank you for the opportunity and your consideration for this position.`

        this.#request
            .StartRepeat("para2")
            .StartRepeatItem()
            .AddLine("para2Line", line4)
            .EndRepeatItem()
            .StartRepeatItem()
            .AddLine("para2Line", line5)
            .EndRepeatItem()
            .EndRepeat("para2");

        this.#request.AddLine("name", fullName);

        return this.#request.Build(this.ReportName)
    }
}
